import { FC, memo } from 'react';

type TempusLogoProps = {
  onClick?: () => void;
};

const TempusLogo: FC<TempusLogoProps> = ({ onClick }): JSX.Element => {
  return (
    <svg width="151" height="55" viewBox="0 0 151 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.4402 36.5757C67.0922 36.8274 65.8892 36.8489 64.8311 36.6404C63.7729 36.4319 63.0192 35.9286 62.5699 35.1304C62.3452 34.7205 62.2039 34.3035 62.1459 33.8793C62.0952 33.455 62.0771 32.8977 62.0916 32.2075V31.6358V22.3544L65.0484 20.6821V31.4633V31.7869C65.0412 32.2542 65.0484 32.6245 65.0702 32.8977C65.0992 33.1638 65.1717 33.3831 65.2877 33.5557C65.5268 33.9296 65.9072 34.1489 66.429 34.2136C66.9509 34.2783 67.6213 34.2532 68.4402 34.1381V36.5757ZM60.0913 27.1921V24.9271H68.4402V27.1921H60.0913Z"
        fill="black"
      />
      <path
        d="M76.2911 36.8993C75.1025 36.8993 74.0445 36.644 73.1168 36.1335C72.1964 35.6229 71.4753 34.9111 70.9535 33.9979C70.439 33.0847 70.1816 32.0528 70.1816 30.9024C70.1816 29.6512 70.4317 28.5511 70.9317 27.6019C71.4391 26.6456 72.142 25.9085 73.0407 25.3908C73.9394 24.8659 74.9649 24.6035 76.1172 24.6035C77.3347 24.6035 78.3784 24.8911 79.248 25.4663C80.1177 26.0344 80.7554 26.8469 81.1613 27.9039C81.5744 28.9538 81.7229 30.1761 81.607 31.5711H78.6827V30.4925C78.6827 29.6943 78.5993 29.0544 78.4327 28.5726C78.266 28.0836 78.0051 27.7241 77.65 27.4941C77.2949 27.264 76.8274 27.1489 76.2476 27.1489C75.5808 27.1489 75.0301 27.2855 74.5952 27.5588C74.1604 27.8248 73.8379 28.2239 73.6278 28.756C73.4176 29.2881 73.3125 29.9532 73.3125 30.7513C73.3125 31.4776 73.4176 32.096 73.6278 32.6065C73.8379 33.1099 74.1532 33.4945 74.5735 33.7606C74.9938 34.0266 75.5084 34.1596 76.1172 34.1596C76.6897 34.1596 77.1825 34.0338 77.5956 33.7822C78.0159 33.5305 78.3349 33.1673 78.5523 32.6928L81.5092 33.5341C81.2193 34.2316 80.8098 34.8356 80.2807 35.3461C79.7517 35.8494 79.1429 36.2342 78.4544 36.5002C77.7659 36.7662 77.0449 36.8993 76.2911 36.8993ZM72.3993 31.5711V29.3924H80.1829V31.5711H72.3993Z"
        fill="black"
      />
      <path
        d="M97.8748 29.6944C97.8748 29.1911 97.7916 28.7561 97.625 28.3893C97.4585 28.0226 97.219 27.7422 96.9073 27.5481C96.6029 27.3539 96.2374 27.2568 95.8093 27.2568C95.404 27.2568 95.0451 27.3539 94.7334 27.5481C94.4291 27.735 94.1896 28.001 94.0157 28.3462C93.8491 28.6841 93.7658 29.076 93.7658 29.5219L92.5264 28.7021C92.5264 27.9183 92.715 27.2209 93.0916 26.6097C93.4689 25.9913 93.9796 25.5095 94.6243 25.1644C95.2772 24.8193 95.9906 24.6467 96.7665 24.6467C97.6434 24.6467 98.3862 24.8229 98.9949 25.1752C99.6036 25.5275 100.06 26.0273 100.365 26.6744C100.677 27.3143 100.832 28.0622 100.832 28.9179V36.5757H97.8748V29.6944ZM83.7212 36.5757V24.9271H86.3305V28.7885H86.6997V36.5757H83.7212ZM90.8087 29.6944C90.8087 29.1911 90.7254 28.7561 90.5588 28.3893C90.3923 28.0226 90.1528 27.7422 89.8418 27.5481C89.5368 27.3539 89.1713 27.2568 88.7438 27.2568C88.3378 27.2568 87.9789 27.3539 87.6672 27.5481C87.3629 27.735 87.1234 28.001 86.9495 28.3462C86.783 28.6841 86.6997 29.076 86.6997 29.5219L85.4605 28.659C85.4605 27.9112 85.6489 27.2317 86.0262 26.6205C86.4101 26.0093 86.9281 25.5275 87.5803 25.1752C88.2398 24.8229 88.9679 24.6467 89.7652 24.6467C90.6495 24.6467 91.3923 24.8372 91.9936 25.2183C92.5956 25.5922 93.0415 26.1028 93.3311 26.7499C93.6207 27.3899 93.7658 28.1125 93.7658 28.9179V36.5757H90.8087V29.6944Z"
        fill="black"
      />
      <path
        d="M109.421 36.8993C108.29 36.8993 107.326 36.6368 106.529 36.1119C105.739 35.5798 105.141 34.85 104.735 33.9224C104.337 32.9948 104.137 31.9378 104.137 30.7513C104.137 29.5721 104.333 28.5187 104.725 27.5912C105.123 26.6636 105.706 25.9337 106.475 25.4016C107.25 24.8695 108.181 24.6035 109.268 24.6035C110.37 24.6035 111.341 24.8659 112.182 25.3908C113.03 25.9158 113.682 26.6456 114.139 27.5803C114.603 28.5151 114.834 29.5721 114.834 30.7513C114.834 31.909 114.606 32.9553 114.15 33.89C113.7 34.8248 113.063 35.5618 112.236 36.1011C111.417 36.6332 110.479 36.8993 109.421 36.8993ZM103.638 40.9845V24.9271H106.246V32.9085H106.616V39.3021L103.638 40.9845ZM108.943 34.2891C109.559 34.2891 110.073 34.1345 110.486 33.8253C110.899 33.5161 111.203 33.0955 111.399 32.5634C111.602 32.0312 111.704 31.4273 111.704 30.7513C111.704 30.0826 111.598 29.4822 111.388 28.9501C111.178 28.4181 110.859 27.9974 110.432 27.6882C110.004 27.3718 109.472 27.2136 108.834 27.2136C108.232 27.2136 107.739 27.361 107.355 27.6559C106.971 27.9435 106.688 28.3497 106.507 28.8747C106.333 29.3995 106.246 30.0251 106.246 30.7513C106.246 31.4848 106.337 32.1139 106.518 32.6389C106.7 33.1638 106.989 33.5701 107.388 33.8577C107.786 34.1453 108.305 34.2891 108.943 34.2891Z"
        fill="black"
      />
      <path
        d="M121.597 36.9208C120.219 36.9208 119.191 36.5721 118.509 35.8746C117.836 35.1772 117.415 34.4401 117.248 33.6635C117.089 32.887 117.009 32.1535 117.009 31.4632V24.9271H120.009V30.4278C120.009 31.0174 120.053 31.5711 120.14 32.0888C120.227 32.6065 120.455 33.0811 120.824 33.5125C121.202 33.944 121.771 34.1596 122.531 34.1596C122.952 34.1596 123.343 34.077 123.706 33.9116C124.075 33.739 124.387 33.4083 124.64 32.9193C124.901 32.4231 125.031 31.7293 125.031 30.8377L126.728 31.6358C126.728 32.5849 126.535 33.4622 126.152 34.2676C125.768 35.0657 125.188 35.7092 124.412 36.1982C123.644 36.6799 122.706 36.9208 121.597 36.9208ZM125.401 36.5757V32.7144H125.031V24.9271H128.011V36.5757H125.401Z"
        fill="black"
      />
      <path
        d="M135.067 36.8993C134.089 36.8993 133.229 36.7519 132.49 36.457C131.404 36.0131 130.568 35.2253 130.105 34.1705L132.849 32.6281C132.929 32.9948 133.078 33.3112 133.295 33.5772C133.512 33.8361 133.788 34.0338 134.121 34.1705C134.462 34.307 134.849 34.3754 135.284 34.3754C135.639 34.3754 135.941 34.3286 136.187 34.2352C136.44 34.1417 136.632 34.0087 136.763 33.8361C136.893 33.6635 136.958 33.455 136.958 33.2105C136.958 32.9661 136.897 32.7791 136.774 32.6496C136.658 32.5131 136.411 32.38 136.034 32.2506C135.665 32.1212 135.03 31.9378 134.132 31.7005C133.161 31.4561 132.392 31.1828 131.827 30.8808C131.262 30.5788 130.853 30.2193 130.599 29.8022C130.345 29.3851 130.218 28.8747 130.218 28.2706C130.218 27.53 130.411 26.8828 130.795 26.3292C131.179 25.7755 131.722 25.3513 132.425 25.0565C133.128 24.7544 133.951 24.6035 134.893 24.6035C135.806 24.6035 136.618 24.7437 137.328 25.0241C138.355 25.4254 139.08 26.0987 139.552 27.0555L136.85 28.5942C136.814 28.3066 136.716 28.0585 136.556 27.85C136.397 27.6415 136.183 27.4761 135.915 27.3538C135.646 27.2316 135.327 27.1561 134.958 27.1273C134.596 27.1058 134.277 27.1346 134.002 27.2136C133.733 27.2855 133.523 27.4005 133.371 27.5588C133.226 27.7097 133.154 27.8967 133.154 28.1196C133.154 28.3281 133.226 28.5008 133.371 28.6373C133.516 28.7667 133.798 28.9034 134.219 29.0472C134.639 29.1838 135.327 29.3708 136.285 29.6081C137.183 29.8381 137.901 30.1078 138.437 30.417C138.973 30.719 139.364 31.0821 139.611 31.5064C139.857 31.9306 139.98 32.4484 139.98 33.0595C139.98 33.8504 139.785 34.5372 139.394 35.1196C139.002 35.6949 138.437 36.1371 137.697 36.4463C136.958 36.7483 136.081 36.8993 135.067 36.8993Z"
        fill="black"
      />
      <path
        d="M34.585 29.098L19.5278 37.6915L12 33.3944V36.5751L19.5278 40.8714L34.585 32.278L42.1143 36.5751L49.6428 32.278V29.098L42.1143 33.3944L34.585 29.098Z"
        fill="black"
      />
      <path
        d="M42.1143 27.2568L49.1911 23.218L42.1143 27.2568L34.585 22.9605L19.5278 31.5539L12.4517 27.515L27.2823 19.0504L27.5156 18.9231L26.8381 18.5361L19.9871 14.6268L27.0564 10.5857L49.1911 23.218L49.6428 22.9605L27.0564 10.0699L19.5278 14.367V17.175L24.5976 20.0669L12 27.2568V30.0655L19.5278 34.3618L34.585 25.7684L42.1143 30.0655L49.6428 25.7684V22.9605L42.1143 27.2568Z"
        fill="black"
      />
    </svg>
  );
};

export default memo(TempusLogo);
