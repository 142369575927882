import { memo } from "react";

const WarnIcon = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.80757 0.929088C9.33502 0.0236374 10.666 0.0236374 11.1934 0.929088L19.8174 15.7336C20.3408 16.6321 19.6795 17.75 18.6245 17.75H1.37647C0.321518 17.75 -0.339855 16.6321 0.183546 15.7336L8.80757 0.929088Z"
        fill="#FF0F0F"
      />
      <path
        d="M8.75049 6.50392V10.2325C8.75049 10.9252 9.31008 11.4868 10.0004 11.4868C10.6907 11.4868 11.2504 10.925 11.2502 10.2322L11.2495 6.50367C11.2493 5.82482 10.7109 5.26935 10.0347 5.25049C9.33129 5.23088 8.75049 5.79776 8.75049 6.50392Z"
        fill="white"
      />
      <path
        d="M11.2499 13.9962C11.2502 13.3033 10.6906 12.7412 10.0002 12.7412C9.30999 12.7412 8.75049 13.303 8.75049 13.9956C8.75049 14.6882 9.30999 15.25 10.0002 15.25C10.6901 15.25 11.2495 14.6886 11.2499 13.9962Z"
        fill="white"
      />
    </svg>
  );
};
export default memo(WarnIcon);
