import Words from './words';

const zz: { [word in Words]: string } = {
  tempus: '~~~~~====={{{{{(((((tempus)))))}}}}}=====~~~~~',
  max: '~~~~~====={{{{{(((((max)))))}}}}}=====~~~~~',
  min: '~~~~~====={{{{{(((((min)))))}}}}}=====~~~~~',
  community: '~~~~~====={{{{{(((((community)))))}}}}}=====~~~~~',
  settings: '~~~~~====={{{{{(((((settings)))))}}}}}=====~~~~~',
  connectWallet: '~~~~~====={{{{{(((((connectWallet)))))}}}}}=====~~~~~',
  xxxPending: '~~~~~====={{{{{(((((xxxPending)))))}}}}}=====~~~~~',
  selectWallet: '~~~~~====={{{{{(((((selectWallet)))))}}}}}=====~~~~~',
  walletSelectorDisclaimer: '~~~~~====={{{{{(((((walletSelectorDisclaimer)))))}}}}}=====~~~~~',
  metamaskConnected: '~~~~~====={{{{{(((((metamaskConnected)))))}}}}}=====~~~~~',
  changeNetworkRejected: '~~~~~====={{{{{(((((changeNetworkRejected)))))}}}}}=====~~~~~',
  changeNetworkRejectedExplain: '~~~~~====={{{{{(((((changeNetworkRejectedExplain)))))}}}}}=====~~~~~',
  unsupportedNetwork: '~~~~~====={{{{{(((((unsupportedNetwork)))))}}}}}=====~~~~~',
  unsupportedNetworkExplain: '~~~~~====={{{{{(((((unsupportedNetworkExplain)))))}}}}}=====~~~~~',
  walletConnectConnected: '~~~~~====={{{{{(((((walletConnectConnected)))))}}}}}=====~~~~~',
  errorConnectingWallet: '~~~~~====={{{{{(((((errorConnectingWallet)))))}}}}}=====~~~~~',
  viewRecentTransactions: '~~~~~====={{{{{(((((viewRecentTransactions)))))}}}}}=====~~~~~',
  walletOverview: '~~~~~====={{{{{(((((walletOverview)))))}}}}}=====~~~~~',
  switchWallet: '~~~~~====={{{{{(((((switchWallet)))))}}}}}=====~~~~~',
  connectedWallet: '~~~~~====={{{{{(((((connectedWallet)))))}}}}}=====~~~~~',
  viewOnXxx: '~~~~~====={{{{{(((((viewOnXxx)))))}}}}}=====~~~~~',
  pendingTransactions: '~~~~~====={{{{{(((((pendingTransactions)))))}}}}}=====~~~~~',
  transactionHistory: '~~~~~====={{{{{(((((transactionHistory)))))}}}}}=====~~~~~',
  clear: '~~~~~====={{{{{(((((clear)))))}}}}}=====~~~~~',
  asset: '~~~~~====={{{{{(((((asset)))))}}}}}=====~~~~~',
  assetName: '~~~~~====={{{{{(((((assetName)))))}}}}}=====~~~~~',
  protocol: '~~~~~====={{{{{(((((protocol)))))}}}}}=====~~~~~',
  protocolName: '~~~~~====={{{{{(((((protocolName)))))}}}}}=====~~~~~',
  clearFilter: '~~~~~====={{{{{(((((clearFilter)))))}}}}}=====~~~~~',
  apply: '~~~~~====={{{{{(((((apply)))))}}}}}=====~~~~~',
  fixedApr: '~~~~~====={{{{{(((((fixedApr)))))}}}}}=====~~~~~',
  lifeTimeApr: '~~~~~====={{{{{(((((lifeTimeApr)))))}}}}}=====~~~~~',
  xxxApr: '~~~~~====={{{{{(((((xxxApr)))))}}}}}=====~~~~~',
  aprRange: '~~~~~====={{{{{(((((aprRange)))))}}}}}=====~~~~~',
  lpApr: '~~~~~====={{{{{(((((lpApr)))))}}}}}=====~~~~~',
  fiat: '~~~~~====={{{{{(((((fiat)))))}}}}}=====~~~~~',
  crypto: '~~~~~====={{{{{(((((crypto)))))}}}}}=====~~~~~',
  pool: '~~~~~====={{{{{(((((pool)))))}}}}}=====~~~~~',
  xxxOfPool: '~~~~~====={{{{{(((((xxxOfPool)))))}}}}}=====~~~~~',
  poolRatio: '~~~~~====={{{{{(((((poolRatio)))))}}}}}=====~~~~~',
  redemption: '~~~~~====={{{{{(((((redemption)))))}}}}}=====~~~~~',
  earlyRedemption: '~~~~~====={{{{{(((((earlyRedemption)))))}}}}}=====~~~~~',
  swap: '~~~~~====={{{{{(((((swap)))))}}}}}=====~~~~~',
  tvl: '~~~~~====={{{{{(((((tvl)))))}}}}}=====~~~~~',
  totalValueLocked: '~~~~~====={{{{{(((((totalValueLocked)))))}}}}}=====~~~~~',
  manage: '~~~~~====={{{{{(((((manage)))))}}}}}=====~~~~~',
  basic: '~~~~~====={{{{{(((((basic)))))}}}}}=====~~~~~',
  basicSubTitle: '~~~~~====={{{{{(((((basicSubTitle)))))}}}}}=====~~~~~',
  advanced: '~~~~~====={{{{{(((((advanced)))))}}}}}=====~~~~~',
  advancedSubTitle: '~~~~~====={{{{{(((((advancedSubTitle)))))}}}}}=====~~~~~',
  deposit: '~~~~~====={{{{{(((((deposit)))))}}}}}=====~~~~~',
  withdraw: '~~~~~====={{{{{(((((withdraw)))))}}}}}=====~~~~~',
  mint: '~~~~~====={{{{{(((((mint)))))}}}}}=====~~~~~',
  removeLiquidity: '~~~~~====={{{{{(((((removeLiquidity)))))}}}}}=====~~~~~',
  provideLiquidity: '~~~~~====={{{{{(((((provideLiquidity)))))}}}}}=====~~~~~',
  earlyRedeem: '~~~~~====={{{{{(((((earlyRedeem)))))}}}}}=====~~~~~',
  operationDisabledByConfig: '~~~~~====={{{{{(((((operationDisabledByConfig)))))}}}}}=====~~~~~',
  askUsOnDiscord: '~~~~~====={{{{{(((((askUsOnDiscord)))))}}}}}=====~~~~~',
  depositDisabledByConfig: '~~~~~====={{{{{(((((depositDisabledByConfig)))))}}}}}=====~~~~~',
  mintDisabledByConfig: '~~~~~====={{{{{(((((mintDisabledByConfig)))))}}}}}=====~~~~~',
  depositDisabledNoLiquidity: '~~~~~====={{{{{(((((depositDisabledNoLiquidity)))))}}}}}=====~~~~~',
  depositDisabledPoolMaturity: '~~~~~====={{{{{(((((depositDisabledPoolMaturity)))))}}}}}=====~~~~~',
  depositDisabledNegative: '~~~~~====={{{{{(((((depositDisabledNegative)))))}}}}}=====~~~~~',
  withdrawDisabledNoLiquidity: '~~~~~====={{{{{(((((withdrawDisabledNoLiquidity)))))}}}}}=====~~~~~',
  withdrawDisabledNoDeposit: '~~~~~====={{{{{(((((withdrawDisabledNoDeposit)))))}}}}}=====~~~~~',
  withdrawDisabledNegative: '~~~~~====={{{{{(((((withdrawDisabledNegative)))))}}}}}=====~~~~~',
  mintDisabledPoolMaturity: '~~~~~====={{{{{(((((mintDisabledPoolMaturity)))))}}}}}=====~~~~~',
  swapDisabledNoLiquidity: '~~~~~====={{{{{(((((swapDisabledNoLiquidity)))))}}}}}=====~~~~~',
  swapDisabledNoShares: '~~~~~====={{{{{(((((swapDisabledNoShares)))))}}}}}=====~~~~~',
  swapDisabledPoolMaturity: '~~~~~====={{{{{(((((swapDisabledPoolMaturity)))))}}}}}=====~~~~~',
  provideLiquidityDisabledNoDeposit: '~~~~~====={{{{{(((((provideLiquidityDisabledNoDeposit)))))}}}}}=====~~~~~',
  provideLiquidityDisabledNoPrincipals: '~~~~~====={{{{{(((((provideLiquidityDisabledNoPrincipals)))))}}}}}=====~~~~~',
  provideLiquidityDisabledNoYields: '~~~~~====={{{{{(((((provideLiquidityDisabledNoYields)))))}}}}}=====~~~~~',
  provideLiquidityDisabledPoolMaturity: '~~~~~====={{{{{(((((provideLiquidityDisabledPoolMaturity)))))}}}}}=====~~~~~',
  removeLiquidityDisabledNoDeposit: '~~~~~====={{{{{(((((removeLiquidityDisabledNoDeposit)))))}}}}}=====~~~~~',
  removeLiquidityDisabledNoLpTokens: '~~~~~====={{{{{(((((removeLiquidityDisabledNoLpTokens)))))}}}}}=====~~~~~',
  removeLiquidityDisabledPoolMaturity: '~~~~~====={{{{{(((((removeLiquidityDisabledPoolMaturity)))))}}}}}=====~~~~~',
  earlyRedemptionDisabledNoLiquidity: '~~~~~====={{{{{(((((earlyRedemptionDisabledNoLiquidity)))))}}}}}=====~~~~~',
  availableToDeposit: '~~~~~====={{{{{(((((availableToDeposit)))))}}}}}=====~~~~~',
  availableToDepositXxx: '~~~~~====={{{{{(((((availableToDepositXxx)))))}}}}}=====~~~~~',
  volume: '~~~~~====={{{{{(((((volume)))))}}}}}=====~~~~~',
  fees: '~~~~~====={{{{{(((((fees)))))}}}}}=====~~~~~',
  term: '~~~~~====={{{{{(((((term)))))}}}}}=====~~~~~',
  startDate: '~~~~~====={{{{{(((((startDate)))))}}}}}=====~~~~~',
  maturity: '~~~~~====={{{{{(((((maturity)))))}}}}}=====~~~~~',
  maturityXxx: '~~~~~====={{{{{(((((maturityXxx)))))}}}}}=====~~~~~',
  timeLeft: '~~~~~====={{{{{(((((timeLeft)))))}}}}}=====~~~~~',
  currentPosition: '~~~~~====={{{{{(((((currentPosition)))))}}}}}=====~~~~~',
  principalsAndYields: '~~~~~====={{{{{(((((principalsAndYields)))))}}}}}=====~~~~~',
  principals: '~~~~~====={{{{{(((((principals)))))}}}}}=====~~~~~',
  xxxPrincipals: '~~~~~====={{{{{(((((xxxPrincipals)))))}}}}}=====~~~~~',
  yields: '~~~~~====={{{{{(((((yields)))))}}}}}=====~~~~~',
  xxxYields: '~~~~~====={{{{{(((((xxxYields)))))}}}}}=====~~~~~',
  lpTokens: '~~~~~====={{{{{(((((lpTokens)))))}}}}}=====~~~~~',
  xxxLpTokens: '~~~~~====={{{{{(((((xxxLpTokens)))))}}}}}=====~~~~~',
  staked: '~~~~~====={{{{{(((((staked)))))}}}}}=====~~~~~',
  approve: '~~~~~====={{{{{(((((approve)))))}}}}}=====~~~~~',
  approved: '~~~~~====={{{{{(((((approved)))))}}}}}=====~~~~~',
  approving: '~~~~~====={{{{{(((((approving)))))}}}}}=====~~~~~',
  approvalFailed: '~~~~~====={{{{{(((((approvalFailed)))))}}}}}=====~~~~~',
  execute: '~~~~~====={{{{{(((((execute)))))}}}}}=====~~~~~',
  executing: '~~~~~====={{{{{(((((executing)))))}}}}}=====~~~~~',
  xxxFailed: '~~~~~====={{{{{(((((xxxFailed)))))}}}}}=====~~~~~',
  xxxDeclined: '~~~~~====={{{{{(((((xxxDeclined)))))}}}}}=====~~~~~',
  xxxDeclinedMessage: '~~~~~====={{{{{(((((xxxDeclinedMessage)))))}}}}}=====~~~~~',
  insufficientLiquidity: '~~~~~====={{{{{(((((insufficientLiquidity)))))}}}}}=====~~~~~',
  insufficientLiquidityMessage: '~~~~~====={{{{{(((((insufficientLiquidityMessage)))))}}}}}=====~~~~~',
  profitLoss: '~~~~~====={{{{{(((((profitLoss)))))}}}}}=====~~~~~',
  currentValue: '~~~~~====={{{{{(((((currentValue)))))}}}}}=====~~~~~',
  from: '~~~~~====={{{{{(((((from)))))}}}}}=====~~~~~',
  to: '~~~~~====={{{{{(((((to)))))}}}}}=====~~~~~',
  and: '~~~~~====={{{{{(((((and)))))}}}}}=====~~~~~',
  via: '~~~~~====={{{{{(((((via)))))}}}}}=====~~~~~',
  balance: '~~~~~====={{{{{(((((balance)))))}}}}}=====~~~~~',
  balanceXxx: '~~~~~====={{{{{(((((balanceXxx)))))}}}}}=====~~~~~',
  lifeTimeYield: '~~~~~====={{{{{(((((lifeTimeYield)))))}}}}}=====~~~~~',
  fixedYield: '~~~~~====={{{{{(((((fixedYield)))))}}}}}=====~~~~~',
  yieldAtMaturity: '~~~~~====={{{{{(((((yieldAtMaturity)))))}}}}}=====~~~~~',
  estimatedYieldAtMaturity: '~~~~~====={{{{{(((((estimatedYieldAtMaturity)))))}}}}}=====~~~~~',
  totalAvailableAtMaturity: '~~~~~====={{{{{(((((totalAvailableAtMaturity)))))}}}}}=====~~~~~',
  variableYield: '~~~~~====={{{{{(((((variableYield)))))}}}}}=====~~~~~',
  amountReceivedXxx: '~~~~~====={{{{{(((((amountReceivedXxx)))))}}}}}=====~~~~~',
  estimatedAmountReceived: '~~~~~====={{{{{(((((estimatedAmountReceived)))))}}}}}=====~~~~~',
  estimated: '~~~~~====={{{{{(((((estimated)))))}}}}}=====~~~~~',
  feesTooltipInfo: '~~~~~====={{{{{(((((feesTooltipInfo)))))}}}}}=====~~~~~',
  selectPlaceholder: '~~~~~====={{{{{(((((selectPlaceholder)))))}}}}}=====~~~~~',
  warningEthGasFees: '~~~~~====={{{{{(((((warningEthGasFees)))))}}}}}=====~~~~~',
  selectTokenFirst: '~~~~~====={{{{{(((((selectTokenFirst)))))}}}}}=====~~~~~',
  interestRateProtectionTooltipText: '~~~~~====={{{{{(((((interestRateProtectionTooltipText)))))}}}}}=====~~~~~',
  liquidityProvisionTooltipText: '~~~~~====={{{{{(((((liquidityProvisionTooltipText)))))}}}}}=====~~~~~',
  slippageTolerance: '~~~~~====={{{{{(((((slippageTolerance)))))}}}}}=====~~~~~',
  slippageTooltip: '~~~~~====={{{{{(((((slippageTooltip)))))}}}}}=====~~~~~',
  auto: '~~~~~====={{{{{(((((auto)))))}}}}}=====~~~~~',
  language: '~~~~~====={{{{{(((((language)))))}}}}}=====~~~~~',
  mobileNotSupported: '~~~~~====={{{{{(((((mobileNotSupported)))))}}}}}=====~~~~~',
  mobileLink: '~~~~~====={{{{{(((((mobileLink)))))}}}}}=====~~~~~',
  unstaked: '~~~~~====={{{{{(((((unstaked)))))}}}}}=====~~~~~',
  xxxStakedPrincipals: '~~~~~====={{{{{(((((xxxStakedPrincipals)))))}}}}}=====~~~~~',
  xxxStakedYields: '~~~~~====={{{{{(((((xxxStakedYields)))))}}}}}=====~~~~~',
  mintDescription: '~~~~~====={{{{{(((((mintDescription)))))}}}}}=====~~~~~',
  swapDescription: '~~~~~====={{{{{(((((swapDescription)))))}}}}}=====~~~~~',
  provideLiquidityDescription: '~~~~~====={{{{{(((((provideLiquidityDescription)))))}}}}}=====~~~~~',
  removeLiquidityDescription: '~~~~~====={{{{{(((((removeLiquidityDescription)))))}}}}}=====~~~~~',
  poolActionDisabledTitle: '~~~~~====={{{{{(((((poolActionDisabledTitle)))))}}}}}=====~~~~~',
  selectNetwork: '~~~~~====={{{{{(((((selectNetwork)))))}}}}}=====~~~~~',
  combinedApr: '~~~~~====={{{{{(((((combinedApr)))))}}}}}=====~~~~~',
  governance: '~~~~~====={{{{{(((((governance)))))}}}}}=====~~~~~',
  unsupported: '~~~~~====={{{{{(((((unsupported)))))}}}}}=====~~~~~',
  switchNetwork: '~~~~~====={{{{{(((((switchNetwork)))))}}}}}=====~~~~~',
  unsupportedNetworkTooltipTitle: '~~~~~====={{{{{(((((unsupportedNetworkTooltipTitle)))))}}}}}=====~~~~~',
  unsupportedNetworkTooltipText1: '~~~~~====={{{{{(((((unsupportedNetworkTooltipText1)))))}}}}}=====~~~~~',
  unsupportedNetworkTooltipText2: '~~~~~====={{{{{(((((unsupportedNetworkTooltipText2)))))}}}}}=====~~~~~',
  allPools: '~~~~~====={{{{{(((((allPools)))))}}}}}=====~~~~~',
  backToDashboard: '~~~~~====={{{{{(((((backToDashboard)))))}}}}}=====~~~~~',
  contractAddresses: '~~~~~====={{{{{(((((contractAddresses)))))}}}}}=====~~~~~',
  termsAndConditions: '~~~~~====={{{{{(((((termsAndConditions)))))}}}}}=====~~~~~',
  copyToclipboard: '~~~~~====={{{{{(((((copyToclipboard)))))}}}}}=====~~~~~',
  copied: '~~~~~====={{{{{(((((copied)))))}}}}}=====~~~~~',
  earliestMaturity: '~~~~~====={{{{{(((((earliestMaturity)))))}}}}}=====~~~~~',
  latestMaturity: '~~~~~====={{{{{(((((latestMaturity)))))}}}}}=====~~~~~',
  timeToMaturity: '~~~~~====={{{{{(((((timeToMaturity)))))}}}}}=====~~~~~',
  slippageError: '~~~~~====={{{{{(((((slippageError)))))}}}}}=====~~~~~',
  disableInputByNegativeYield: '~~~~~====={{{{{(((((disableInputByNegativeYield)))))}}}}}=====~~~~~',
  upTo: '~~~~~====={{{{{(((((upTo)))))}}}}}=====~~~~~',
  since: '~~~~~====={{{{{(((((since)))))}}}}}=====~~~~~',
  poolMatured: '~~~~~====={{{{{(((((poolMatured)))))}}}}}=====~~~~~',
  matured: '~~~~~====={{{{{(((((matured)))))}}}}}=====~~~~~',
  // key: '~~~~~====={{{{{(((((key)))))}}}}}=====~~~~~',
};
export default zz;
