import React, { memo } from 'react';

const GovernanceIcon = () => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.71842 5.25702H3.64079V14.8832C3.64079 16.3318 4.85439 17.5 6.35921 17.5H17.2816C18.7864 17.5 20 16.3318 20 14.8832C20 13.4347 18.7864 12.2664 17.2816 12.2664H16.3592V2.64024C16.3592 1.19168 15.1456 0.023448 13.6408 0H2.71842C1.2136 0 0 1.16823 0 2.64024C0 4.11224 1.2136 5.25702 2.71842 5.25702ZM17.2816 14.0187C17.7914 14.0187 18.204 14.4159 18.204 14.9066C18.204 15.3973 17.7914 15.7945 17.2816 15.7945H8.93203C9.15044 15.2337 9.15044 14.603 8.93203 14.0422H17.2816V14.0187ZM5.43687 1.75234H13.6408C14.1506 1.75234 14.5388 2.14952 14.5632 2.64024V12.2664H5.43692L5.43687 1.75234ZM6.35925 14.0187C6.86905 14.0187 7.28163 14.4159 7.28163 14.9066C7.28163 15.3973 6.86903 15.7945 6.35925 15.7945C5.84948 15.7945 5.43687 15.3973 5.43687 14.9066C5.43687 14.4159 5.84948 14.0187 6.35925 14.0187ZM2.71846 1.75234H3.64084V2.64024C3.64084 3.13097 3.22824 3.52813 2.71846 3.52813C2.20868 3.52813 1.79608 3.13095 1.79608 2.64024C1.79608 2.14952 2.20868 1.75234 2.71846 1.75234Z"
      fill="#222222"
    />
  </svg>
);
export default memo(GovernanceIcon);
