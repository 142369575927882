import { FC, memo } from 'react';
import IconProps from './IconProps';

const CopyIcon: FC<IconProps> = props => {
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 0H6.22222C5.51498 0 4.8367 0.280952 4.3366 0.781049C3.83651 1.28115 3.55556 1.95942 3.55556 2.66667V3.55556H2.66667C1.95942 3.55556 1.28115 3.83651 0.781049 4.3366C0.280952 4.8367 0 5.51498 0 6.22222V13.3333C0 14.0406 0.280952 14.7189 0.781049 15.219C1.28115 15.719 1.95942 16 2.66667 16H9.77778C10.485 16 11.1633 15.719 11.6634 15.219C12.1635 14.7189 12.4444 14.0406 12.4444 13.3333V12.4444H13.3333C14.0406 12.4444 14.7189 12.1635 15.219 11.6634C15.719 11.1633 16 10.485 16 9.77778V2.66667C16 1.95942 15.719 1.28115 15.219 0.781049C14.7189 0.280952 14.0406 0 13.3333 0ZM10.6667 13.3333C10.6667 13.5691 10.573 13.7952 10.4063 13.9619C10.2396 14.1286 10.0135 14.2222 9.77778 14.2222H2.66667C2.43092 14.2222 2.20483 14.1286 2.03813 13.9619C1.87143 13.7952 1.77778 13.5691 1.77778 13.3333V6.22222C1.77778 5.98647 1.87143 5.76038 2.03813 5.59368C2.20483 5.42698 2.43092 5.33333 2.66667 5.33333H9.77778C10.0135 5.33333 10.2396 5.42698 10.4063 5.59368C10.573 5.76038 10.6667 5.98647 10.6667 6.22222V13.3333ZM14.2222 9.77778C14.2222 10.0135 14.1286 10.2396 13.9619 10.4063C13.7952 10.573 13.5691 10.6667 13.3333 10.6667H12.4444V6.22222C12.4444 5.51498 12.1635 4.8367 11.6634 4.3366C11.1633 3.83651 10.485 3.55556 9.77778 3.55556H5.33333V2.66667C5.33333 2.43092 5.42698 2.20483 5.59368 2.03813C5.76038 1.87143 5.98647 1.77778 6.22222 1.77778H13.3333C13.5691 1.77778 13.7952 1.87143 13.9619 2.03813C14.1286 2.20483 14.2222 2.43092 14.2222 2.66667V9.77778Z"
        fill={props.fillColor || '#222222'}
      />
    </svg>
  );
};
export default memo(CopyIcon);
